export default [
    'none',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 1px 2px 0 rgba(63,63,68,0.15)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 1px 2px 0 rgba(63,63,68,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 1px 2px 0 rgba(63,63,68,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 1px 2px 0 rgba(63,63,68,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 1px 2px 0 rgba(63,63,68,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 1px 2px 0 rgba(63,63,68,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 1px 2px 0 rgba(63,63,68,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 1px 2px 0 rgba(63,63,68,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 1px 2px 0 rgba(63,63,68,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 1px 2px 0 rgba(63,63,68,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 1px 2px 0 rgba(63,63,68,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 1px 2px 0 rgba(63,63,68,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 1px 2px 0 rgba(63,63,68,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 1px 2px 0 rgba(63,63,68,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 1px 2px 0 rgba(63,63,68,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 1px 2px 0 rgba(63,63,68,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 1px 2px 0 rgba(63,63,68,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 1px 2px 0 rgba(63,63,68,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 1px 2px 0 rgba(63,63,68,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 1px 2px 0 rgba(63,63,68,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 1px 2px 0 rgba(63,63,68,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 1px 2px 0 rgba(63,63,68,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 1px 2px 0 rgba(63,63,68,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 1px 2px 0 rgba(63,63,68,0.25)'
]